body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
}

.full-width-svg {
  width: 100%; /* Full width */
  height: auto; /* Maintain aspect ratio */
}